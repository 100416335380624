<template>
  <div>

    <CRow>
      <CCol lg="12">
        <h1 class="main-header" v-translate translate-context="faq_category">Faq Question List</h1>
        <CCard class="">
          <CCardHeader>
            <div class="card-header-actions">
              <router-link :to="{name: 'faqCategory.list'}">
                <CButton block variant="outline" color="success" v-translate translate-context="list.actions">Return to Category</CButton>
              </router-link>
              <router-link :to="{name: 'faqQuestion.new', params: {category: category}}">
                <CButton block color="primary" v-translate translate-context="list.actions">Create</CButton>
              </router-link>
            </div>
          </CCardHeader>
          <CCardBody class="px-0">
            <CDataTable
                :hover="false"
                :bordered="true"
                :items="questions.results"
                :fields="fields"
                :column-filter="{external: true,lazy:true}"
                :column-filter-value="filters"
                :sorter="{external: true, resetable:true}"
                :loading="loading"
                :sorter-value="sorting"
                @update:column-filter-value="updateFilters"
                @update:sorter-value="updateSorting"
                class="custom-table custom-table--clean custom-table--has-filters"
            >
              <template #no-items-view>
                <div class="text-center">
                  <h5 v-translate>No items found</h5>
                </div>
              </template>
              <template #sorting-icon="{ state, classes }">
                <div :class="classes">
                  <CIcon name="cipCaretUp" size="lg" v-if="state === 'asc' || state === 0"></CIcon>
                  <CIcon name="cipCaretUp" size="lg" v-else></CIcon>
                </div>
              </template>
              <template #actions="{item}">
                <td class="text-right">
                  <CButton variant="outline" color="primary" class="mr-2"
                           @click="$router.push({name: 'faqQuestion.edit', params: {category: category, id: item.id}})"
                           v-translate translate-context="list.actions"
                  >
                    Edit
                  </CButton>
                  <CButton @click="onDelete(item.id)" variant="outline" color="danger"
                           v-translate translate-context="list.actions"
                  >
                    Delete
                  </CButton>
                </td>
              </template>
              <template #under-table>
                <CPagination
                    v-show="questions.pagination.num_pages > 1"
                    :activePage="questions.pagination.page"
                    @update:activePage="updatePage"
                    :pages="questions.pagination.num_pages"
                />
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>


    </CRow>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'ListFaqQuestion',
  data() {
    return {
      category: null,
      loading: true,
      fields: [
        {key: 'id', label: this.$gettextInterpolate('ID', 'faq_question.list.tbl'), _style: 'width:100px;'},
        {key: 'question', label: this.$gettextInterpolate('Question', 'faq_question.list.tbl')},
        {key: 'tags', label: this.$gettextInterpolate('Tags','faq_question.list.tbl')},
        {key: 'is_active', label: this.$gettextInterpolate('Active', 'faq_question.list.tbl')},
        {key: 'order', label: this.$gettextInterpolate('Order', 'faq_question.list.tbl')},
        {
          key: 'actions',
          label: this.$gettextInterpolate('Actions', 'faq_question.list.tbl'),
          sorter: false,
          filter: false
        }
      ],
      pagination: {
        page_size: 20,
        page: 1,
      },
      sorting: {
        column: null,
        asc: false
      },
      filters: {},
    }
  },
  async mounted() {
    this.category = this.$route.params.category
    await this.fetchList()
  },
  computed: {
    ...mapGetters('faq', [
      'questions',
    ]),
  },
  methods: {
    ...mapActions('faq', [
      'fetchQuestionList',
      'deleteQuestion'
    ]),
    async fetchList() {
      this.loading = true
      await this.fetchQuestionList({
        category: this.category,
        filters: this.filters,
        pagination: this.pagination,
        sorting: this.sorting
      })
      this.pagination.page = this.questions.pagination.page
      this.loading = false
    },
    updateFilters(filters) {
      this.filters = filters
      this.pagination.page = 1
      this.fetchList()
    },
    updateSorting(sorting) {
      this.sorting = sorting
      this.fetchList()
    },
    updatePage(page) {
      this.pagination.page = page
      this.fetchList()
    },
    async onDelete(id) {
      // TODO: Trovare un componente per fare i confirm
      if (!confirm('Are you sure?')) {
        return
      }
      await this.deleteQuestion(id)
      await this.fetchList()
    }
  }
}
</script>

<style scoped>

</style>